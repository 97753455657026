/* eslint-disable */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-await-in-loop */

// Import useEffect
import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ShoppingCard from "layouts/dashboard/components/Store/components/ShoppingCard";

// context imports
import { useMaterialUIController, setCurrentStore, setStore } from "context";

// Firebase imports
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../../../Firebase";

function Store() {
  const [controller, dispatch] = useMaterialUIController();
  const { currentStore, userStores, store } = controller;

  const [storeNotLoaded, setStoreNotLoaded] = useState(true);
  const [inactiveStudies, setInactiveStudies] = useState([]);

  useEffect(() => {
    const getInactiveStudies = async () => {
      try { 
        const colRef = await getDocs(collection(db, "STUDY_INACTIVE"));
        const _inactiveStudies = colRef.docs.map((el) => el.id);
        setInactiveStudies(_inactiveStudies);
      } catch(err){
        // console.log('error', err);
      }
    };
    getInactiveStudies();
  }, []);

  useEffect(() => {
    const renderStore = async () => {
      if (currentStore === "") {
        const storeUser = getAuth().currentUser.uid;
        const inactiveColRef = await getDocs(collection(db, "STUDY_INACTIVE"));
        const _inactiveStudies = inactiveColRef.docs.map((el) => el.id);
        const colRef = await getDocs(collection(db, "USERS", storeUser, "STUDIES"));
        const userStudies = colRef.docs.map((el) => el.id).filter(item => !_inactiveStudies.includes(item));
        setCurrentStore(dispatch, userStudies[0]);
        console.log(currentStore);
      }
      const studyStoreDocs = collection(db, "STUDY_INVENTORY", currentStore, "STORE_ITEMS");
      console.log("STORE ITEMS >>>", studyStoreDocs);
      const q = query(studyStoreDocs, orderBy("storeIndex"));
      const orderedDocs = await getDocs(q);
      const studyStoreArray = orderedDocs.docs.map((el) => ({ ...el.data(), id: el.id }));
      setStore(dispatch, studyStoreArray);
      setStoreNotLoaded(false);
    };
    renderStore();
    console.log(currentStore);
    console.log(inactiveStudies);
    console.log(userStores);
    console.log("STORE >>>", store);
  }, [currentStore]);

  const featuredItems = store
    .map((x) => x)
    .filter((x) => x.featured)
    .map((a) => (
      <React.Fragment key={a.id}>
        {!a.hidden && <>
          <Grid item xs={12} md={6} lg={4} sx={{ mb: "1rem" }}>
            <MDBox mb={1.5} key={a.id}>
              <ShoppingCard
                id={a.id}
                study={a.study}
                storeIndex={a.storeIndex}
                image={a.image}
                title={a.title}
                download={a.download}
                dimensions={a.dimensions}
                description={a.description}
                listDescription={a.listDescription === undefined ? [] : a.listDescription}
                quantity={a.quantity}
                quantityOptions={a.quantityOptions}
                languageVersion={a.languageVersion}
                customization={a.customization}
                customizationRequired={a.customizationRequired}
                customizationOptions={a.customizationOptions}
                storeData={a.storeData}
                active={a.active}
                hidden={a.hidden}
                meta={a.meta}
                action={{
                  type: a.action.type,
                  route: a.action.route,
                  color: a.action.color,
                  label: a.action.label,
                }}
              />
            </MDBox>
          </Grid>
        </>}
      </React.Fragment>
    ));

  const storeItems = store
    .map((x) => x)
    .filter((x) => !x.featured)
    .map((a) => (
      <React.Fragment key={a.id}>
        {!a.hidden && <>
          <Grid item xs={12} md={6} lg={4} sx={{ mb: "1rem" }}>
            <MDBox mb={1.5} key={a.id}>
              <ShoppingCard
                id={a.id}
                study={a.study}
                storeIndex={a.storeIndex}
                image={a.image}
                title={a.title}
                download={a.download}
                dimensions={a.dimensions}
                description={a.description}
                listDescription={a.listDescription === undefined ? [] : a.listDescription}
                quantity={a.quantity}
                quantityOptions={a.quantityOptions}
                languageVersion={a.languageVersion}
                customization={a.customization}
                customizationRequired={a.customizationRequired}
                customizationOptions={a.customizationOptions}
                storeData={a.storeData}
                inactiveStudies={inactiveStudies}
                active={a.active}
                hidden={a.hidden}
                meta={a.meta}
                action={{
                  type: a.action.type,
                  route: a.action.route,
                  color: a.action.color,
                  label: a.action.label,
                }}
              />
            </MDBox>
          </Grid>
        </>}
      </React.Fragment>
    ));

  return (
    <MDBox py={3}>
      <Grid container spacing={3} sx={{ mb: "1.5rem" }}>
        {storeNotLoaded && (
          <MDBox p={5}>
            <CircularProgress color="inherit" />
          </MDBox>
        )}
        {featuredItems}
      </Grid>
      <Grid container spacing={3}>
        {storeItems}
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <MDTypography variant="body2" color="dark">
            <strong>Note: </strong>
            Print quantity minimums are set for cost efficiency purposes. If you are interested in a
            quantity of a specific tool at an amount lower than the listed minimum, please send your
            request through the Storefront&apos;s &quot;Contact&quot; page.
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Store;
